import IAnagrafica from "./IAnagrafica";
import IFattoSignificativo, { exampleFattoSignificativo } from "./IFattoSignificativo";
import IFirma from "./IFirma";
import ITerapia from "./ITerapia";
import IUscita from "./IUscita";

export default interface IGiornata {
    Id: number;
    Id_Famiglia: number;
    Data: string;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    Uscite: IUscita[];
    Terapie: ITerapia[];
    FattiSignificativi: IFattoSignificativo;
    FirmeMattina: IFirma[];
    FirmePomeriggio: IFirma[];
    Educatori: IAnagrafica[];
}

export const exampleGiornata: IGiornata = {
    Id: 0,
    Id_Famiglia: 0,
    Data: '',
    Creation: '',
    Timestamp: '',

    Uscite: [],
    Terapie: [],
    FattiSignificativi: exampleFattoSignificativo,
    FirmeMattina: [],
    FirmePomeriggio: [],
    Educatori: [],
};
