export default interface IFirma {
    Id: number;
    Id_Anagrafica: number;
    Id_Giornata: number;
    IsMattina: boolean;
    Creation: string;
    Timestamp: string;
}

export const exampleFirma: IFirma = {
    Id: 0,
    Id_Anagrafica: 0,
    Id_Giornata: 0,
    IsMattina: true,
    Creation: '',
    Timestamp: '',
};