import React from 'react';
import Famiglie from '../components/Famiglie';

function Home() {
  return (
    <>
        <Famiglie />
    </>
  );
}

export default Home;
